import * as React from 'react';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import {
  getUserPreferencesSubject,
  gridInitialState,
  GridPreferences,
  updateUserSettings,
  UserPreferences
} from '../../../store/User';
import { useSnackbar } from '../../../Context/SnackbarContext';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography
} from '@mui/material';
import { getTabs } from '../../../utils/helpers';
import { DefaultInventoryGridColumnDefs } from '../../Pages/Inventory/inventoryGridColumnDefs';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { TextBlock } from '../../Atoms/Text';

export interface NewViewModalParams {
  createOpen: boolean;
  setCreateOpen: (state: boolean) => void;
  setOpen: (state: boolean) => void;
}

export const NewViewModal = ({ createOpen, setCreateOpen, setOpen }: NewViewModalParams) => {
  const { setSnack } = useSnackbar();
  const [userPreferences, setUserPreferences] = useState<UserPreferences>({} as UserPreferences);
  const [sourceTabOpts, setSourceTabOpts] = useState<string[]>();
  const [newName, setNewName] = useState('');
  const [useSourceTabSettings, setUseSourceTabSettings] = useState<boolean>(true);
  const [sourceTab, setSourceTab] = useState('');

  useEffect(() => {
    const prefSub = getUserPreferencesSubject().subscribe((prefs) => {
      setUserPreferences(prefs);
      const tabOpts = getTabs(prefs.content, 'inventory_grid_');
      setSourceTabOpts(tabOpts);
    });

    return () => {
      if (prefSub) prefSub.unsubscribe();
    };
  }, []);

  const handleSubmitForm = async () => {
    const currentSettings: UserPreferences = JSON.parse(JSON.stringify(userPreferences));
    if (currentSettings && currentSettings.content) {
      let newView: GridPreferences = {
        customView: true,
        muiConfig: gridInitialState(DefaultInventoryGridColumnDefs) as GridInitialStatePro,
        disabledFields: []
      };

      if (useSourceTabSettings) {
        const currentTabTableState = JSON.parse(
          JSON.stringify(currentSettings?.content?.[`inventory_grid_${sourceTab}`])
        );
        newView = {
          ...newView,
          ...currentTabTableState
        };
      }

      // Add new view.
      currentSettings.content[`inventory_grid_${newName}`] = newView;
      const { data, error } = await updateUserSettings(currentSettings, `inventory_grid_${newName.replace(' ', '_')}`);
      if (data) setSnack({ message: 'Your view was created successfully', type: 'success', open: true });
      if (error)
        setSnack({ message: `There was an error creating your view: \n${error.message}`, type: 'error', open: true });

      handleClose();
    }
  };

  const handleClose = () => {
    setNewName('');
    setSourceTab('');
    setCreateOpen(false);
    setOpen(true);
  };

  return (
    <Dialog
      data-testid="new-view-modal"
      open={createOpen}
      onClose={handleClose}
      fullWidth>
      <DialogTitle>
        <TextBlock
          weight="bold"
          size="lg"
          color="grey6">
          Create New View
        </TextBlock>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500]
        })}>
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ minHeight: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="grid space-y-5 justify-center">
          <TextField
            required
            data-testid="new-view-name-input"
            sx={{ width: '20rem' }}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="View Name *"
            size="small"
          />
          <FormControl
            required
            sx={{ width: '20rem' }}
            disabled={!useSourceTabSettings}
            size="small">
            <InputLabel
              sx={{
                fontFamily: 'sans-serif',
                fontSize: '0.85rem',
                color: 'rgb(107 114 128)',
                fontWeight: 300
              }}
              id="source-tab-select-label">
              Source Tab
            </InputLabel>
            <Select
              labelId="source-tab-select-label"
              id="source-tab-select"
              value={sourceTab}
              label="Source Tab"
              onChange={(e) => setSourceTab(e.target.value || '')}>
              {sourceTabOpts?.map((opt) => (
                <MenuItem
                  key={opt}
                  value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>
            <FormControlLabel
              control={
                <Switch
                  data-testid="use-source-tab-switch"
                  checked={useSourceTabSettings}
                  onChange={(e) => setUseSourceTabSettings(e.target.checked)}
                />
              }
              label={
                <Typography
                  sx={{
                    fontFamily: 'sans-serif',
                    fontSize: '0.8rem',
                    color: 'rgb(107 114 128)',
                    fontWeight: 600
                  }}>
                  Use source tab settings
                </Typography>
              }
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ margin: 'auto', pb: 2 }}>
        <button
          className={DEFAULT_CANCEL_BUTTON_STYLE}
          onClick={handleClose}>
          Back
        </button>
        <button
          data-testid="submit-new-view"
          disabled={!newName || (!sourceTab && useSourceTabSettings)}
          onClick={handleSubmitForm}
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          type="submit">
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};
