import React, { useEffect, useState } from 'react';
import { InputLabel, MenuItem, Select, SelectChangeEvent, Switch, TextField } from '@mui/material';
import { Text } from '../../Atoms/Text';
import {
  getDefaultPreferences,
  getUser,
  getUserPreferencesSubject,
  getUserSubject,
  PrefKey,
  updateUserData,
  updateUserSettings,
  UserPreferences
} from '../../../store/User';
import { SKIP_ACCOUNT_ROLES } from '../../../store/Role';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { useInventory } from '../../../store/Inventory';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

interface HistoricOption {
  name: string;
  value: string;
}

interface EmailNotification {
  title: string;
  switchLabel: string;
  default: boolean;
}

interface InputErrors {
  first_name: string;
  last_name: string;
  email: string | React.ReactElement;
}

export interface FormMeta {
  email: {
    install_updates_email: EmailNotification;
    support_tickets_email: EmailNotification;
    comment_created_email: EmailNotification;
    comment_mention_email: EmailNotification;
  };
  app: {
    support_tickets: EmailNotification;
    comment_created: EmailNotification;
    comment_mention: EmailNotification;
  };
  historic: {
    disconnects: {
      title: string;
      options: HistoricOption[];
    };
    cancellations: {
      title: string;
      options: HistoricOption[];
    };
  };
}

const defaultFormMeta: FormMeta = {
  email: {
    install_updates_email: {
      title: 'Service Installations',
      switchLabel: 'Email Notifications',
      default: false
    } as EmailNotification,
    support_tickets_email: {
      title: 'Support Tickets',
      switchLabel: 'Email Notifications',
      default: false
    } as EmailNotification,
    comment_created_email: {
      title: 'Comment Created',
      switchLabel: 'Email Notifications',
      default: false
    } as EmailNotification,
    comment_mention_email: {
      title: 'Comment Mention',
      switchLabel: 'Email Notifications',
      default: false
    } as EmailNotification
  },
  app: {
    support_tickets: {
      title: 'Support Tickets',
      switchLabel: 'App Notifications',
      default: false
    } as EmailNotification,
    comment_mention: {
      title: 'Comments that @Mention me',
      switchLabel: 'App Notifications',
      default: true
    } as EmailNotification,
    comment_created: {
      title: 'All Comments on any item',
      switchLabel: 'App Notifications',
      default: false
    } as EmailNotification
  },
  historic: {
    disconnects: {
      title: 'Show disconnects for the last...',
      options: [
        { name: '6 months', value: '6' },
        { name: '12 months', value: '12' },
        { name: '24 months', value: '24' },
        { name: '36 months', value: '36' },
        { name: 'All Time', value: 'All' },
        { name: 'None', value: 'None' }
      ] as HistoricOption[]
    },
    cancellations: {
      title: 'Show cancellations for the last...',
      options: [
        { name: 'All Time', value: 'All' },
        { name: 'None', value: 'None' }
      ] as HistoricOption[]
    }
  }
};

const switchSx = {
  '.Mui-checked': {
    color: '#2E01A4 !important'
  },
  '.MuiSwitch-track': {
    backgroundColor: '#878EBE !important'
  }
};

const MySettingsTab = () => {
  const [settings, setSettings] = useState<UserPreferences>(getDefaultPreferences());
  const [savingSettings, setSavingSettings] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [inputErrors, setInputErrors] = useState<InputErrors>({ first_name: '', last_name: '', email: '' });
  const { fetchInventory } = useInventory();
  const { setSnack } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const settingsSub = getUserPreferencesSubject().subscribe((preferences) => setSettings(preferences));
    const userSubscription = getUserSubject().subscribe((user) => {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
    });

    return () => {
      if (userSubscription) userSubscription.unsubscribe();
      if (settingsSub) settingsSub.unsubscribe();
    };
  }, []);

  const handleSave = async () => {
    setSavingSettings(true);
    const { error } = await updateUserSettings(settings, 'User Setting');
    const { error: errorUser } = await updateUserData(firstName, lastName, email);
    if (errorUser) setInputErrors({ ...inputErrors, email: errorUser.errors?.email || errorUser.message });
    setSavingSettings(false);
    if (error || errorUser) {
      setSnack({
        type: 'error',
        message: 'Failed to save settings.',
        open: true
      });
    } else {
      fetchInventory(true);
      setSnack({
        type: 'success',
        message: 'Settings saved.',
        open: true
      });
    }
  };

  const handleSettingChange = (id: string, section: PrefKey, event?: SelectChangeEvent) => {
    const settingsUpdate = { ...settings };

    if (section === 'notifications') {
      const updatedSection = settingsUpdate?.content?.notifications;
      if (!updatedSection) return;
      let updated = updatedSection.find((s) => s.id === id);
      if (!updated) {
        const defaultItem = id.includes('email')
          ? defaultFormMeta.email[id as keyof typeof defaultFormMeta.email]
          : defaultFormMeta.app[id as keyof typeof defaultFormMeta.app];

        updated = { id, index: updatedSection.length, enabled: !defaultItem.default };
        updatedSection.push(updated);
      } else {
        updated.enabled = !updated.enabled;
      }
    } else {
      const updatedSection = settingsUpdate?.content?.historic;
      if (!updatedSection) return;
      const updated = updatedSection.find((s) => s.id === id);
      if (!updated) return;
      updated.value = event?.target?.value;
    }

    setSettings(settingsUpdate);
  };

  const renderNotifications = () => {
    return Object.keys(defaultFormMeta.app).map((notif) => {
      const appNotification = settings?.content.notifications.find((i) => i.id === notif);
      const defaultApp = defaultFormMeta.app[notif as keyof typeof defaultFormMeta.app];
      const emailNotificaiton = settings?.content.notifications.find((i) => i.id === `${notif}_email`);
      const defaultEmail = defaultFormMeta.email[`${notif}_email` as keyof typeof defaultFormMeta.email];

      return (
        <tr key={`${notif}-setting`}>
          <td className="pl-2">
            <Text
              color="grey6"
              weight="normal"
              size="sm">
              {defaultFormMeta.app[notif as keyof typeof defaultFormMeta.app].title}
            </Text>
          </td>
          <td>
            <div className="flex !ml-auto items-center space-x-3">
              <div>
                <Switch
                  sx={switchSx}
                  size="small"
                  checked={appNotification ? appNotification.enabled : defaultApp.default}
                  onChange={() => handleSettingChange(notif, 'notifications')}
                />
              </div>
            </div>
          </td>
          <td>
            <div className="flex !ml-auto items-center space-x-3">
              <div>
                <Switch
                  sx={switchSx}
                  size="small"
                  checked={emailNotificaiton ? emailNotificaiton.enabled : defaultEmail.default}
                  onChange={() => handleSettingChange(`${notif}_email`, 'notifications')}
                />
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFirstName(value);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLastName(value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  const validate = (type: string, value: string) => {
    const errorsUpdate = { ...inputErrors, [type]: '' };
    switch (type) {
      case 'email': {
        if (!validator.isEmail(value)) {
          errorsUpdate[type] = 'Enter a valid email address.';
          break;
        }
        break;
      }
      case 'last_name':
      case 'first_name':
        if (!validator.isAlpha(value.replace(/\s|-/g, ''))) errorsUpdate[type] = 'Enter a valid name.';
        break;
    }
    setInputErrors(errorsUpdate);
  };

  return (
    <div>
      <div className="flex mt-4 px-1">
        <Text
          className="font-semibold w-full"
          color="grey9"
          size="sm">
          Personal Details
        </Text>
      </div>
      <hr className="my-2 h-0.5 border-t-1" />
      <div className="pl-2">
        <div className="flex flex-row pb-4">
          <InputLabel
            className="basis-1/5 mt-2"
            htmlFor="first_name"
            sx={{ fontSize: '.8rem', color: '#606060' }}>
            First Name
          </InputLabel>
          <TextField
            type="text"
            className="w-full flex-initial"
            name="first_name"
            size={'small'}
            value={firstName || ''}
            placeholder="First Name"
            required={true}
            onBlur={() => validate('first_name', firstName || '')}
            onChange={handleFirstNameChange}
            error={!!inputErrors.first_name}
            helperText={inputErrors.first_name}
            InputProps={{ style: { fontSize: '.8rem', height: '2rem' } }}
          />
        </div>
        <div className="flex flex-row pb-4">
          <InputLabel
            className="basis-1/5 mt-2"
            htmlFor="last_name"
            sx={{ fontSize: '.8rem', color: '#606060' }}>
            Last Name
          </InputLabel>
          <TextField
            type="text"
            className="w-full"
            name="last_name"
            size={'small'}
            value={lastName || ''}
            placeholder="Last Name"
            required={true}
            onBlur={() => validate('last_name', lastName || '')}
            onChange={handleLastNameChange}
            error={!!inputErrors.last_name}
            helperText={inputErrors.last_name}
            InputProps={{ style: { fontSize: '.8rem', height: '2rem' } }}
          />
        </div>
        {getUser().roles.every(({ name }) => name && !SKIP_ACCOUNT_ROLES.includes(name)) && (
          <div className="flex flex-row pb-4">
            <InputLabel
              className="basis-1/5 mt-2"
              htmlFor="email"
              sx={{ fontSize: '.8rem', color: '#606060' }}>
              Email
            </InputLabel>
            <TextField
              type="email"
              className="w-full"
              name="email"
              size={'small'}
              value={email || ''}
              placeholder="Email"
              required={true}
              onBlur={() => validate('email', email || '')}
              onChange={handleEmailChange}
              error={!!inputErrors.email}
              helperText={inputErrors.email}
              InputProps={{ style: { fontSize: '.8rem', height: '2rem' } }}
            />
          </div>
        )}
      </div>
      <table className="mt-4 w-full [&_tr]:border-y">
        <thead>
          <tr className="border-none text-left [&>*]:font-normal [&>*]:p-1">
            <th>
              <Text
                className="font-semibold w-full"
                color="grey9"
                size="sm">
                Notifications
              </Text>
            </th>
            <th>
              <Text
                color="grey9"
                weight="medium"
                size="sm">
                In-App
              </Text>
            </th>
            <th>
              <Text
                color="grey9"
                weight="medium"
                size="sm">
                Email
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>{renderNotifications()}</tbody>
      </table>

      <div className="flex mt-8 px-1">
        <Text
          className="font-semibold w-full"
          color="grey9"
          size="sm">
          Historic Data
        </Text>
      </div>
      <hr className="my-2 h-0.5 border-t-1" />
      {settings?.content.historic.map((item) => (
        <div
          key={item.id}
          className="flex items-center space-x-4 px-1 pb-2">
          <Text
            color="grey6"
            size="sm">
            {defaultFormMeta.historic[item.id as keyof typeof defaultFormMeta.historic].title}
          </Text>
          <div className="flex !ml-auto items-center space-x-3">
            <div>
              <Select
                onChange={(event) => handleSettingChange(item.id, 'historic', event)}
                sx={{ fontSize: '.875rem', height: '1.5rem' }}
                value={item.value}>
                {defaultFormMeta.historic[item.id as keyof typeof defaultFormMeta.historic].options.map((option) => (
                  <MenuItem
                    key={option.name}
                    value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      ))}

      <hr className="mt-3 h-0.5 border-t-2" />

      <div className="justify-between mt-5">
        <button
          onClick={() => navigate('/')}
          className={DEFAULT_CANCEL_BUTTON_STYLE}>
          Back
        </button>
        <button
          disabled={savingSettings || !!inputErrors.first_name || !!inputErrors.last_name || !!inputErrors.email}
          onClick={handleSave}
          className={`${DEFAULT_ADVANCE_BUTTON_STYLE} float-right`}>
          Save
        </button>
      </div>
    </div>
  );
};

export default MySettingsTab;
