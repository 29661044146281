import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { DEFAULT_ADVANCE_BUTTON_STYLE } from '../../../utils/styleHelpers';
import {
  getCurrentPreferences,
  getCustomViewSubject,
  gridInitialState,
  updateUserSettings,
  UserPreferences
} from '../../../store/User';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { IconButton } from '@mui/material';
import { DefaultInventoryGridColumnDefs } from '../../Pages/Inventory/inventoryGridColumnDefs';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { TextBlock, TextSpan } from '../../Atoms/Text';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { Icon } from '../../Atoms/Icon';
import { NewViewModal } from './NewViewModal';

export interface ViewsModalParams {
  open: boolean;
  setOpen: (state: boolean) => void;
  handleClose: () => void;
}

export interface View {
  name: string;
  isEdit: boolean;
}

export const ViewsModal = ({ open, setOpen, handleClose }: ViewsModalParams) => {
  const [views, setViews] = useState<View[]>([]);
  const [name, setName] = useState<{ [key: number]: string }>({});
  const [loading, setLoading] = useState(false);
  const { setSnack } = useSnackbar();
  const [createOpen, setCreateOpen] = useState(false);

  useEffect(() => {
    const viewSub = getCustomViewSubject().subscribe((views) =>
      setViews(views.map((v) => ({ name: v, isEdit: false })))
    );
    return () => {
      if (viewSub) viewSub.unsubscribe();
    };
  }, []);

  const handleEditClick = (index: number) => {
    const update: View[] = [...views];
    update[index].isEdit = true;
    setViews(update);
    setName({ ...name, [index]: update[index].name });
  };

  const handleDelete = async (index: number) => {
    setLoading(true);
    const viewName = views[index].name;

    const currentSettings = getCurrentPreferences();
    delete currentSettings.content[`inventory_grid_${viewName}`];
    const { data, error } = await updateUserSettings(currentSettings, `inventory_grid_${viewName}`);
    if (data) setSnack({ message: 'Your view was deleted successfully', type: 'success', open: true });
    if (error)
      setSnack({ message: `There was an error deleting your view: \n${error.message}`, type: 'error', open: true });

    setLoading(false);
  };

  const handleSave = async (index: number) => {
    setLoading(true);
    const update: View[] = [...views];
    const currentViewName = update[index].name;
    const newName = name[index];

    const currentSettings: UserPreferences = getCurrentPreferences();
    const currentView = { ...currentSettings.content[`inventory_grid_${currentViewName}`] };
    delete currentSettings.content[`inventory_grid_${currentViewName}`];

    currentSettings.content[`inventory_grid_${newName}`] = {
      ...currentView,
      muiConfig: currentView.muiConfig || (gridInitialState(DefaultInventoryGridColumnDefs) as GridInitialStatePro),
      disabledFields: currentView.disabledFields || []
    };

    const { data, error } = await updateUserSettings(currentSettings, `inventory_grid_${newName}`);
    if (data) setSnack({ message: 'Your view was updated successfully', type: 'success', open: true });
    if (error)
      setSnack({ message: `There was an error updating your view: \n${error.message}`, type: 'error', open: true });
    setLoading(false);
  };

  return (
    <>
      <Dialog
        data-testid="views-modal"
        open={open}
        onClose={handleClose}
        fullWidth>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', paddingBottom: '.7rem' }}>
          <TextBlock
            weight="bold"
            size="lg"
            color="grey6">
            Edit Views
          </TextBlock>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}>
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ height: 300, overflow: 'auto', paddingTop: 'unset', paddingRight: '.7rem', paddingLeft: '.7rem' }}>
          <div className="border-b-2 pb-2">
            <button
              type="button"
              className="flex ml-auto self-center items-center space-x-2 mr-2"
              onClick={() => {
                setCreateOpen(true);
                setOpen(false);
              }}>
              <Icon
                type="plus"
                className="p-2"
              />
              <TextSpan
                color="indigo"
                size="sm14">
                New View
              </TextSpan>
            </button>
          </div>

          {loading ? (
            <div className="mt-[5rem] items-center self-center">
              <ComponentSpinner label="Saving..." />
            </div>
          ) : (
            <>
              {views.map((view: View, index: number) => (
                <div
                  className="flex border-b py-4 mx-4"
                  key={`${view.name}-${index}`}>
                  {view.isEdit && (
                    <div className="flex grow space-x-3">
                      <TextField
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="View Name"
                        value={name[index]}
                        onChange={(e) => setName({ ...name, [index]: e.target.value })}
                        fullWidth
                        variant="outlined"
                      />
                      <IconButton
                        sx={{
                          color: 'rgb(107 114 128)'
                        }}
                        size="small"
                        onClick={() => handleSave(index)}>
                        <DoneIcon />
                      </IconButton>
                    </div>
                  )}
                  {!view.isEdit && (
                    <>
                      {view.name}
                      <div className="ml-auto space-x-0">
                        <IconButton
                          sx={{
                            color: 'rgb(107 114 128)'
                          }}
                          size="small"
                          onClick={() => handleEditClick(index)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            color: 'rgb(107 114 128)'
                          }}
                          size="small"
                          onClick={() => handleDelete(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </>
          )}
          {!views.length && !loading && (
            <div className="flex w-full justify-center mt-[5rem]">
              <TextSpan>No Views</TextSpan>
            </div>
          )}
        </DialogContent>
        <DialogActions sx={{ margin: 'auto', pb: 2 }}>
          <button
            className={DEFAULT_ADVANCE_BUTTON_STYLE}
            onClick={handleClose}>
            Close
          </button>
        </DialogActions>
      </Dialog>
      <NewViewModal
        createOpen={createOpen}
        setCreateOpen={setCreateOpen}
        setOpen={setOpen}
      />
    </>
  );
};
