import { Autocomplete, Box, TextField } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';

export type MenuProps = {
  setSelectedCustomView: (name: string) => void;
  selectedCustomView: string;
  customTabs: string[];
  handleTabChange: (index: number, name: string, customView?: boolean) => void;
};

export const Menu = ({ setSelectedCustomView, customTabs, handleTabChange, selectedCustomView }: MenuProps) => {
  const [viewOptions, setViewOptions] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleCustomTabChange = (event: SyntheticEvent<Element, Event>, value: string) => {
    if (value) {
      setSelectedCustomView(value);
      handleTabChange(0, value, true);
    }
  };

  useEffect(() => {
    setViewOptions(customTabs);
  }, [customTabs, customTabs.length]);

  return (
    <>
      <Autocomplete
        inputValue={searchQuery}
        onInputChange={(e, val: string) => setSearchQuery(val)}
        disableClearable
        onChange={handleCustomTabChange}
        options={viewOptions}
        value={selectedCustomView}
        sx={{ padding: 1, color: 'rgb(107 114 128)', fontSize: '.8rem', width: '12rem' }}
        renderInput={(params) => (
          <TextField
            sx={{ width: '100%', fontSize: '.8rem' }}
            {...params}
            variant="standard"
            placeholder="My Views"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                fontSize: '.8rem',
                color: 'black',
                fontWeight: 500
              }
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <Box
            component="li"
            {...props}
            key={option}
            sx={{
              backgroundColor: selected ? 'rgba(0, 0, 255, 0.1)' : 'inherit',
              fontWeight: selected ? 'bold' : 'normal'
            }}>
            {option}
          </Box>
        )}
      />
    </>
  );
};
