import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { InventoryAsset } from '../../../types';
import { PageLayout } from '../../PageLayout';
import { PageCard } from '../../Compounds/CardWithTitle';
import { DashboardWidgets } from '../../Compounds/DashboardWidgets/DashboardWidgets';
import { DetailsDrawer } from '../../Compounds/DetailsDrawer';
import { getMetaData } from '../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { getInventoryLoadingSubject, getInventorySubject, useInventory } from '../../../store/Inventory';
import {
  GridPreferences,
  PrefKey,
  UserPreferencesContent,
  getCustomViewSubject,
  getUserPreferencesSubject
} from '../../../store/User';
import { FeatureFlags, defaultFeatureFlags, getFeatureFlagSubject } from '../../../Api/useFeatureFlags';
import { CustomizeDashboardDrawer } from '../../Compounds/CustomizeDashboardDrawer';
import {
  contentWithProductFilters,
  getColumnsByProduct,
  getTabData
} from '../../Compounds/UpstackDataGrid/inventoryUtils';
import { UpstackDataGrid } from '../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { getGridColumns } from '../../Compounds/UpstackDataGrid/helpers';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { PermissibleRender } from '../../Compounds/PermissibleRender';
import { Permission } from '../../../store/GeneralStore';
import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';
import { useProductsStore } from '../../../store/Product';
import { useOutsideInventorySchemasStore } from '../../../store/OutsideInventorySchema';
import { Menu as CustomViewTabs } from './../../Compounds/CustomTableViews/Menu';
import { combineLatest } from 'rxjs';
import { Box, Button, Typography, Tab, Tabs, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ViewsModal } from '../../Compounds/CustomTableViews/ViewsModal';

export interface TabProps {
  name: string;
  count: number;
  customView?: boolean;
}
export interface CategorizedData {
  [key: string]: InventoryAsset[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`standard-tab-${index}`}
      aria-labelledby={`standard-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const InventoryPage = () => {
  const page = 'Inventory';
  const [inventoryAssets, setInventoryAssets] = useState<InventoryAsset[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs, setTabs] = useState<TabProps[]>([]);
  const [allTabs, setAllTabs] = useState<TabProps[]>([]);
  const [customTabNames, setCustomTabNames] = useState<string[]>([]);
  const [categorizedData, setCategorizedData] = useState<CategorizedData>({});
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<GridSingleSelectColDef[]>();
  const [selectedCustomView, setSelectedCustomView] = useState<string>('');
  const [search] = useSearchParams();
  const detailId = search.get('detailId');
  const { fetchInventory } = useInventory();
  const { fetchProducts } = useProductsStore();
  const { fetchOutsideInventorySchemas } = useOutsideInventorySchemasStore();
  const [userPreferences, setUserPreferences] = useState<UserPreferencesContent>();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);
  const [open, setOpen] = useState(false);
  const state = { prevpath: location.pathname };
  const theme = useTheme();

  useEffect(() => {
    const dataSubscription = combineLatest({
      inventory: getInventorySubject(),
      settings: getUserPreferencesSubject(),
      inventoryLoading: getInventoryLoadingSubject(),
      flags: getFeatureFlagSubject(),
      customTabNames: getCustomViewSubject()
    }).subscribe(({ inventory, settings, inventoryLoading, flags, customTabNames }) => {
      // Inventory Assets
      setInventoryAssets(inventory);
      const { tabs, categorizedData } = getTabData(inventory);
      setTabs(tabs);
      setCategorizedData(categorizedData);
      const selectedTabName = tabs[selectedTab]?.name || 'All';
      setColumns(
        getGridColumns(
          (categorizedData as CategorizedData)[selectedTabName],
          getColumnsByProduct(selectedTabName),
          `inventory_grid_${selectedTabName}`
        )
      );
      // Inventory Loading
      setInventoryLoading(inventoryLoading);

      // Flags
      setFeatureFlags(flags);

      // User Settings
      setUserPreferences(
        contentWithProductFilters(
          settings.content,
          tabs.map((t) => t.name),
          [...new Set(inventory.map((i) => i.productDisplay || ''))]
        )
      );
      setCustomTabNames(customTabNames);
    });

    fetchInventory();
    fetchProducts();
    fetchOutsideInventorySchemas();

    return () => {
      if (dataSubscription) dataSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (userPreferences) {
      let customTabs: TabProps[] = [];

      if (featureFlags.my_views) {
        customTabs = customTabNames.map((t) => ({ name: t, count: 0, customView: true }));
      }

      if (selectedCustomView && !customTabNames.includes(selectedCustomView)) {
        setSelectedCustomView('');
        handleTabChange(0, 'All');
      }

      setAllTabs(tabs.length ? tabs.concat(customTabs) : []);
    }
  }, [userPreferences, tabs, tabs.length, customTabNames.length]);

  useEffect(() => {
    if (tabs?.length) handleTabChange(0, tabs?.[0]?.name);
  }, [tabs?.length]);

  const tableCardActions = () => {
    if (featureFlags.outside_inventory) {
      return (
        <PermissibleRender requiredPermissions={[Permission.CREATE_OUTSIDE_INVENTORY]}>
          <Link
            role="link"
            title="Add Inventory"
            className="flex space-x-2 items-center"
            data-cy="new-outside-inventory"
            state={state}
            to="/add-inventory">
            <Icon
              type="plus"
              className="p-2"
            />
            <TextSpan
              color="indigo"
              size="sm14">
              Add Inventory
            </TextSpan>
          </Link>
        </PermissibleRender>
      );
    }
  };

  const handleTabChange = (index: number, name: string, customTab?: boolean) => {
    let customIndex = 0;
    if (!customTab) setSelectedCustomView('');
    if (customTab) customIndex = allTabs.findIndex((t) => t.name === name);

    setSelectedTab(customTab ? customIndex : index);

    const columns = getGridColumns(
      categorizedData[name] || categorizedData['All'],
      getColumnsByProduct(name),
      `inventory_grid_${name}`
    );
    setColumns(columns);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PageLayout pageTitle={page}>
      <>
        <PageCard
          title="Dashboard"
          cardActions={<CustomizeDashboardDrawer title="Inventory" />}>
          <DashboardWidgets
            inventoryAssets={inventoryAssets}
            pageTitle={page}
            tickets={[]}
          />
        </PageCard>
        <PageCard
          childrenWrapperClass="min-h-80 max-w-[60vw] min-w-full"
          title={page}
          cardActions={tableCardActions()}>
          <>
            <Tabs
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgb(247 247 247)',
                borderBottom: '1px solid rgb(229 231 235)',
                borderTop: '1px solid rgb(229 231 235)'
              }}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  height: selectedTab <= tabs.length - 1 ? 2 : 0,
                  borderRadius: 1
                }
              }}
              value={selectedTab}
              onChange={(_e, index) => handleTabChange(index, allTabs?.[index]?.name)}>
              {tabs.map((tab, index) => (
                <Tab
                  sx={{ textTransform: 'none', fontSize: '.8rem', color: 'black' }}
                  key={index}
                  label={`${tab.name} (${categorizedData[tab.name].length})`}
                />
              ))}
              {tabs.length > 1 && featureFlags.my_views && (
                <div className={`flex ${selectedCustomView ? 'border-b-2 border-indigo' : ''}`}>
                  <div
                    key="custom-views"
                    className="self-center">
                    <CustomViewTabs
                      setSelectedCustomView={setSelectedCustomView}
                      selectedCustomView={selectedCustomView}
                      customTabs={customTabNames}
                      handleTabChange={handleTabChange}
                    />
                  </div>
                </div>
              )}
              {tabs.length > 1 && featureFlags.my_views && (
                <div
                  className="ml-3 self-center"
                  key="add-custom-view">
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<EditIcon />}
                    sx={{
                      backgroundColor: 'rgb(46 1 164)',
                      borderRadius: '0.25rem',
                      color: 'rgb(255 255 255)',
                      fontWeight: 700,
                      fontSize: '0.8rem',
                      textTransform: 'none'
                    }}
                    onClick={() => setOpen(true)}>
                    Views
                  </Button>
                </div>
              )}
            </Tabs>
            {allTabs.map((tab, index) => (
              <TabPanel
                key={`${index}`}
                index={index}
                value={selectedTab}>
                <UpstackDataGrid
                  title={`Inventory - ${tab.name}`}
                  rows={categorizedData['All']}
                  columns={columns}
                  pinnedColumns={{ left: ['details', 'UPSTACK Managed', 'account_id'] }}
                  loadingData={inventoryLoading}
                  page={`inventory_grid_${tab.name}`}
                  gridSettings={(userPreferences?.[`inventory_grid_${tab.name}` as PrefKey] || {}) as GridPreferences}
                  showSearch
                />
              </TabPanel>
            ))}
          </>
        </PageCard>
        <div className="h-2"></div>
        <DetailsDrawer
          data={inventoryAssets?.find((ia: InventoryAsset) => ia?.id === detailId) || ({} as InventoryAsset)}
          showDrawer={!!detailId}
          link={`/inventory/${detailId}`}
          {...getMetaData('inventory')}
        />
      </>
      <ViewsModal
        setOpen={setOpen}
        open={open}
        handleClose={handleClose}
      />
    </PageLayout>
  );
};
